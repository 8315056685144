import React, { useState } from "react"

export const CartContext = React.createContext()

const Provider = props => {
  const [cart, setCart] = useLocalStorage("cart", [])

  return (
    <CartContext.Provider value={[cart, setCart]}>
      {props.children}
    </CartContext.Provider>
  )
}

const useLocalStorage = (key, initialValue) => {
  const [cart, setCart] = useState(() => {
    try {
      const item = window.localStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (e) {
      return initialValue
    }
  })

  const setValue = value => {
    try {
      const valueToStore = value instanceof Function ? value(cart) : value
      setCart(valueToStore)
      window.localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (e) {
      console.log(e)
    }
  }

  return [cart, setValue]
}

const context = ({ element }) => <Provider>{element}</Provider>

export default context
